import axios from "axios";

let http = axios.create({
  // baseURL: process.env.VUE_APP_URL_API,
  // baseURL: "https://api.idemsaude.com.br/scnrevolution-dev/", // dev
  // baseURL: 'http://192.168.0.4:3003',
  baseURL: 'https://api.idemsaude.com.br/scnrevolution/',
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      localStorage.token ? JSON.parse(localStorage.token) : ""
    }`
  }
});

http.interceptors.request.use(
  function(config) {
    config.headers.Authorization = `Bearer ${
      localStorage.token ? JSON.parse(localStorage.token) : ""
    }`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
