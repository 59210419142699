import http from "@/apps/shared/baseService/config";

const base = {
  rota: "establishment",
  nome: "establishment"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  list_market: data => http.post(`/client/${base.rota}/list`, data)
};

const module = {
  actions: {
    list_marketplace: ({ commit, dispatch, getters }) => {
      return new Promise((resolve, reject) => {
        service
          .list_market(getters.get_establishments_filtros)
          .then(res => {
            dispatch("disableLoading");
            commit("set_establishments_marketplace", res.data);
            resolve(res.data);
          })
          .catch(e => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "error",
              message: e.response.data.message,
              timeout: 6000
            });
            reject(e);
          });
      });
    },
    listar_establishments: ({ commit, dispatch, getters }) => {
      return new Promise((resolve, reject) => {
        if (localStorage.getItem("establishments")) {
          commit(
            "set_establishments",
            JSON.parse(localStorage.getItem("establishments"))
          );
        } else {
          dispatch("enableLoading");
        }
        service
          .listar(getters.get_establishments_filtros)
          .then(res => {
            dispatch("disableLoading");
            localStorage.setItem("establishments", JSON.stringify(res.data));
            commit("set_establishments", res.data);
            resolve(res.data);
          })
          .catch(e => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "error",
              message: e.response.data.message,
              timeout: 6000
            });
            reject(e);
          });
      });
    },
    atualizar_establishment: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_establishment, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_establishment", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_establishments");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_establishment: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_establishment, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_establishment", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_establishments");
          // commit("set_modal_view_establishment", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_establishment: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_establishments");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_establishment: ({ commit }, payload) => {
      if (payload) {
        commit("set_establishment", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_establishment", true);
    },
    fecha_modal_view_establishment: ({ commit }) => {
      commit("set_establishment", {});
      commit("setLinks", []);
      commit("set_modal_view_establishment", false);
    }
  },
  state: {
    establishment: {},
    establishments: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    establishments_marketplace: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    establishments_filtros: {
      limitPerPage: 1000,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_establishment: false
  },
  mutations: {
    set_establishment: (state, payload) => (state.establishment = payload),
    set_establishments: (state, payload) => (state.establishments = payload),
    set_establishments_marketplace: (state, payload) => (state.establishments_marketplace = payload),
    set_modal_view_establishment: (state, payload) =>
      (state.modal_view_establishment = payload)
  },
  getters: {
    get_establishment: state => state.establishment,
    get_establishments: state => state.establishments,
    get_establishments_marketplace: state => state.establishments_marketplace,
    get_modal_view_establishment: state => state.modal_view_establishment,
    get_establishments_filtros: state => state.establishments_filtros
  }
};

const routes = [
  {
    path: `/estabelecimentos`,
    component: () => import("./views/Main.vue"),
    name: "Estabelecimento"
  },
  {
    path: `/mapa-empresas`,
    component: () => import("./views/StablishmentMap.vue"),
    name: "Mapa de Estabelecimento"
  },
  // {
  //   path: `/marketplace`,
  //   component: () => import("./views/Marketplace.vue"),
  //   name: "MarketMaps"
  // },
  {
    path: `/cadastrar-endereco`,
    component: () => import("./views/CreateAddress.vue"),
    name: "Cadastrar Endereço"
  }
];

export default {
  service,
  module,
  routes
};
