export const unilevel_routes = [
  {
    path: "/unilevel",
    component: () => import("../Main.vue"),
    name: "Minha Rede"
  }
  // {
  //   path: "/unilevel",
  //   component: () => import("../Three.vue"),
  //   name: "Minha Rede"
  // }
];

export default {};
